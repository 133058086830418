import { dasa } from './dasaClasses'
export namespace test {
    export class DasaAppUtil {
        public static USER_TYPE_ARRAY = [
            { id: -1, name: 'Select User Type' },
            { id: dasa.Constant.USER_TYPE_BUSINESS_ADMIN, name: 'Business Admin' }

        ];

        //             ,{ id: dasa.Constant.USER_TYPE_BUSINESS_ADMIN_cum_STORE_ADMIN, name: 'Business Cum Store Admin' }

        public static getUserType(): any {
            if (dasa.Utility.loggedInUser.userGroupId == dasa.Constant.USER_TYPE_SUPER_ADMIN) {
                return DasaAppUtil.USER_TYPE_ARRAY;
            } else if (dasa.Utility.loggedInUser.userGroupId == dasa.Constant.USER_TYPE_BUSINESS_ADMIN) {
                return [
                    { id: -1, name: 'Select User Type' },
                    { id: dasa.Constant.USER_TYPE_STORE_ADMIN, name: 'Store Admin' },
                    { id: dasa.Constant.USER_TYPE_DRIVER, name: 'Driver' }
                ];
            } else if (dasa.Utility.loggedInUser.userGroupId == dasa.Constant.USER_TYPE_STORE_ADMIN) {
                return [
                    { id: -1, name: 'Select User Type' },
                    { id: dasa.Constant.USER_TYPE_KITCHEN_USER, name: 'Kitchen User(KOT)' }
                ];
            }
        }
        public static getCountryById(id: number): dasa.Country {
            let country: dasa.Country = {} as dasa.Country;
            for (let i = 0; i < dasa.Utility.countries.length; i++) {
                const element: dasa.Country = dasa.Utility.countries[i] as dasa.Country;
                if (element.id == id) {
                    country = dasa.Utility.countries[i];
                    break;
                }
            }

            return country;
        }

        /**
         * Method return gender string by code
         * @param genderCode 
         * @returns 
         */
        public static getGenderString(genderCode: string): string {
            let str: string = ""
            switch (genderCode) {
                case dasa.Constant.GENDER_MALE:
                    return 'Male';
                case dasa.Constant.GENDER_FEMALE:
                    return 'Female';
                case dasa.Constant.GENDER_TRANSGENDER:
                    return 'Transgender';
                default:
                    return "";
            }
        }

        public static orderStatusArray_pickup: any = [
            { id: dasa.Constant.ORDER_TYPE_PENDING, name: "Pending" },
            { id: dasa.Constant.ORDER_TYPE_ACCEPTED, name: "Accepted" },
            // { id: dasa.Constant.ORDER_TYPE_CANCELED, name: "Cancelled" },
            // { id: dasa.Constant.ORDER_TYPE_DELIVERED, name: "Delivered" },
            { id: dasa.Constant.ORDER_TYPE_READy_FOR_PICKUP, name: "Ready For Pick Up" },
            { id: dasa.Constant.ORDER_TYPE_PICKED, name: "Picked Up" },
           
            // { id: dasa.Constant.ORDER_TYPE_REJECTED, name: "Rejected" }
        ];

        public static orderStatusArray_delivered: any = [
            { id: dasa.Constant.ORDER_TYPE_PENDING, name: "Pending" },
            { id: dasa.Constant.ORDER_TYPE_ACCEPTED, name: "Accepted" },
            // { id: dasa.Constant.ORDER_TYPE_READy_FOR_PICKUP, name: "Ready For Pick Up" },
            { id: dasa.Constant.ORDER_TYPE_OUT_FOR_DELIVERY, name: "Driver on Its Way" },
            // { id: dasa.Constant.ORDER_TYPE_CANCELED, name: "Cancelled" },
            { id: dasa.Constant.ORDER_TYPE_DELIVERED, name: "Delivered" },
            // { id: dasa.Constant.ORDER_TYPE_PICKED, name: "Picked" },
            // { id: dasa.Constant.ORDER_TYPE_PROCESSING, name: "Processing" },
            // { id: dasa.Constant.ORDER_TYPE_REJECTED, name: "Rejected" }
        ];

        public static getOrderStatus(status: number): string {
            let str: string = ""
            switch (status) {
                case dasa.Constant.ORDER_TYPE_ACCEPTED:
                    return 'Accepted';
                case dasa.Constant.ORDER_TYPE_CANCELED:
                    return 'Cancelled (Delivery canceled on Stuart)';
                case dasa.Constant.ORDER_TYPE_DELIVERED:
                    return 'Delivered';
                case dasa.Constant.ORDER_TYPE_PENDING:
                    return 'Pending';
                case dasa.Constant.ORDER_TYPE_PICKED:
                    return 'Picked Up';
                case dasa.Constant.ORDER_TYPE_PROCESSING:
                    return 'Accepted, Processing Delivery';
                case dasa.Constant.ORDER_TYPE_REJECTED:
                    return 'Rejected';
                case dasa.Constant.ORDER_TYPE_READy_FOR_PICKUP:
                    return 'Ready For Pick Up';
                case dasa.Constant.ORDER_TYPE_OUT_FOR_DELIVERY:
                    return 'Out for Delivery';
                    case dasa.Constant.ORDER_TYPE_SEARCHING_FOR_COURIER:
                        return 'Searching for Courier';
                case dasa.Constant.ORDER_TYPE_COURIER_ASSIGNED:
                    return 'Courier Assigned';
                case dasa.Constant.ORDER_TYPE_COURIER_ARRIVING_PICKUP:
                    return 'Courier Arriving to Pickup Food';
                case dasa.Constant.ORDER_TYPE_CUSTOMER_DROPOFF_IMMINENT:
                    return 'Customer Drop Off Imminent';
                case dasa.Constant.ORDER_TYPE_SCHEDULE_ACCEPTED:
                    return 'Accepted (Scheduled Order)';
                case dasa.Constant.ORDER_TYPE_CANCELLED_BY_VENDOR:
                    return 'Canceled by Vendor';
                case dasa.Constant.ORDER_TYPE_CANCELLED_BY_CUSTOMER:
                    return 'Canceled by Customer';
                default:
                    return "";
            }
        }
        /**
         * Make and return copy of an object
         * @param object
         * @returns 
         */
        public static getCopyOfAnObject(object: any): any {
            return JSON.parse(JSON.stringify(object));
        }

        /**
         * Method return User group string by userGroupId
         * @param userGroupId 
         * @returns 
         */
        public static getUserGroupString(userGroupId: number): string {
            switch (userGroupId) {
                case dasa.Constant.USER_TYPE_SUPER_ADMIN:
                    return 'Super Admin';
                case dasa.Constant.USER_TYPE_BUSINESS_ADMIN:
                    return 'Business Admin';
                case dasa.Constant.USER_TYPE_STORE_ADMIN:
                    return 'Store Admin';
                case dasa.Constant.USER_TYPE_DRIVER:
                    return 'Driver';
                case dasa.Constant.USER_TYPE_BUSINESS_ADMIN_cum_STORE_ADMIN:
                    return 'Business cum Super Admin';
                case dasa.Constant.USER_TYPE_KITCHEN_USER:
                    return 'Kitchen(KOT)';

                default:
                    return '';
            }
        }

        /**
         * Method used to get Status String
         */
        public static getStatusString(status: number): string {
            switch (status) {
                case dasa.Constant.STATUS_ENABLED:
                    return 'Enabled';
                case dasa.Constant.STATUS_DISABLED:
                    return 'Disabled';
                default:
                    return '';
            }
        }
        /**
         * 
         * @param deliveryType Method used to get deliveryType String
         */
        public static getDeliveryTypeString(deliveryType: number) {
            switch (deliveryType) {
                case dasa.Constant.DELIVERY_TYPE_PICK_UP_ONLY:
                    return 'Pickup only';
                case dasa.Constant.DELIVERY_TYPE_DELIVERY_ONLY:
                    return 'Delivery only';
                case dasa.Constant.DELIVERY_TYPE_PICKUP_DELIVERY_BOTH:
                    return 'Both';
                default:
                    return '';
            }
        }

        /**
         * Method used to get featured string
         */
        public static getFeaturedString(featuredType: number) {
            switch (featuredType) {
                case dasa.Constant.FEATURED_STORE_NO:
                    return 'No';
                case dasa.Constant.FEATURED_STORE_YES:
                    return 'Yes';
                default:
                    return '';
            }
        }



        /**
         * Method used to get user by user id
         * @param userId 
         * @returns 
         */
        public static getUserById(userId: number): dasa.User {
            let users: dasa.User[] = dasa.Utility.users;
            let u: any = {};
            if (users != undefined && users.length > 0) {
                for (let i: any = 0; i < users.length; i++) {
                    if (userId == users[i].id) {
                        u = users[i];
                        break;
                    }
                }
            }
            return u;
        }


        /**
         * Method used to get user by user id
         * @param userId 
         * @returns 
         */
        public static getBuisnessById(buisnessId: number): dasa.Business {
            let buisness: dasa.Business[] = dasa.Utility.businesses;
            let b: any = {};
            if (buisness != undefined && buisness.length > 0) {
                for (let i: any = 0; i < buisness.length; i++) {
                    if (buisnessId == buisness[i].id) {
                        b = buisness[i];
                        break;
                    }
                }
            }
            return b;
        }

        /**
         * Method used to get business by  id
         * @param userId 
         * @returns 
         */
        public static getBusinessItemById(itemId: number): dasa.User {
            let businessItems: dasa.StoreItem[] = dasa.Utility.businessItems;
            let u: any = {};
            if (businessItems != undefined && businessItems.length > 0) {
                for (let i: any = 0; i < businessItems.length; i++) {
                    if (itemId == businessItems[i].id) {
                        u = businessItems[i];
                        break;
                    }
                }
            }
            return u;
        }


        /**
         * Method used to get store by store id
         * @param userId 
         * @returns 
         */
        public static getStoreById(storeId: number): dasa.Store {
            let store: dasa.Store[] = dasa.Utility.stores;
            let b: any = {};
            if (store != undefined && store.length > 0) {
                for (let i: any = 0; i < store.length; i++) {
                    if (storeId == store[i].id) {
                        b = store[i];
                        break;
                    }
                }
            }
            return b;
        }
        /**
         * 
         * @param order_id Method used to get order by id
         */
        public static getOrderById(order_id: number): dasa.Order {
            let orders: any = dasa.Utility.storeOrders;
            let b: any = {};
            if (orders != undefined && orders.length > 0) {
                for (let i: any = 0; i < orders.length; i++) {
                    if (order_id == orders[i].order.id) {
                        b = orders[i];
                        break;
                    }
                }
            }
            return DasaAppUtil.getCopyOfAnObject(b) as dasa.Order;
        }



        /**
         * Method used to get user by user id
         * @param userId 
         * @returns 
         */
        public static getSupportById(supportId: number): dasa.SupportDetail {
            let support: dasa.SupportDetail[] = dasa.Utility.supportDetails;
            let b: any = {};
            if (support != undefined && support.length > 0) {
                for (let i: any = 0; i < support.length; i++) {
                    if (supportId == support[i].id) {
                        b = support[i];
                        break;
                    }
                }
            }
            return b;
        }


        /**
        * Method used to get Feedback type string
        * @param type
        * @returns 
        */
        public static getFeedbackType(type: number): string {
            let str: string = ""
            switch (type) {
                case dasa.Constant.FEEDBACK_TYPE_FEEDBACK:
                    return 'Feedback';
                case dasa.Constant.FEEDBACK_TYPE_SUGGESTION:
                    return 'Suggestion';
                case dasa.Constant.FEEDBACK_TYPE_COMPLAIN:
                    return 'Complain';
                default:
                    return "";
            }
        }
        /**
         * Method used to get Feedback status string
         * @param status 
         * @returns 
         */
        public static getFeedbackStatus(status: number): string {
            let str: string = ""
            switch (status) {
                case dasa.Constant.FEEDBACK_STATUS_CLOSED:
                    return 'Closed';
                case dasa.Constant.FEEDBACK_STATUS_OPEN:
                    return 'Open';
                default:
                    return "";
            }
        }



        /* get only enable brands list*/
        public static getEnableBrandsList() {
            let brands: dasa.Brand[] = dasa.Utility.brands;
            let disabledBrandIds = [];
            if (dasa.Utility.storeSettings != undefined && dasa.Utility.storeSettings.disabledBrandIds != undefined)
                disabledBrandIds = dasa.Utility.storeSettings.disabledBrandIds;
            let b: any = [];
            if (disabledBrandIds != undefined && disabledBrandIds.length > 0) {
                if (brands != undefined && brands.length > 0) {
                    for (let i: any = 0; i < brands.length; i++) {
                        let isDisabled: boolean = false;
                        for (let k: any = 0; k < disabledBrandIds.length; k++) {
                            if (disabledBrandIds[k] == brands[i].id) {
                                isDisabled = true;
                                break;
                            }
                        }
                        if (isDisabled == false) {
                            b[b.length] = brands[i];
                        }

                    }
                }
            } else {
                b = brands;
            }
            // console.log('getEnableBrandsList', brands)
            return this.getCopyOfAnObject(b);
        }

        /* get only enable category list*/
        public static getEnableCategoryList() {
            let categories: dasa.Category[] = dasa.Utility.categories;
            let disabledCategoryIds = [];
            if (dasa.Utility.storeSettings != undefined && dasa.Utility.storeSettings.disabledBrandIds != undefined)
                disabledCategoryIds = dasa.Utility.storeSettings.disabledCategoryIds;
            let b: any = [];
            if (disabledCategoryIds != undefined && disabledCategoryIds.length > 0) {
                if (categories != undefined && categories.length > 0) {
                    for (let i: any = 0; i < categories.length; i++) {
                        let isDisabled: boolean = false;
                        for (let k: any = 0; k < disabledCategoryIds.length; k++) {
                            if (disabledCategoryIds[k] == categories[i].id) {
                                isDisabled = true;
                                break;
                            }
                        }
                        if (isDisabled == false) {
                            b[b.length] = categories[i];
                        }

                    }
                }
            } else {
                b = categories;
            }
            // console.log('getEnableBrandsList', categories)
            return this.getCopyOfAnObject(b);
        }

        public static getBrandById(brandId: number): dasa.Brand {
            let brands: dasa.Brand[] = dasa.Utility.brands;
            let brand: dasa.Brand = {} as dasa.Brand;
            if (brands != undefined && brands.length > 0) {
                brands.forEach(element => {
                    if (element.id == brandId) {
                        brand = element;

                    }

                });
            }

            return DasaAppUtil.getCopyOfAnObject(brand) as dasa.Brand;
        }

        public static getCategoryById(categoryId: number): dasa.Category {
            let categories: dasa.Category[] = dasa.Utility.categories;
            let category: dasa.Category = {} as dasa.Category;
            if (categories != undefined && categories.length > 0) {
                categories.forEach(element => {
                    if (element.id == categoryId) {
                        category = element;

                    }

                });
            }

            return DasaAppUtil.getCopyOfAnObject(category) as dasa.Category;
        }



        /* get only enable store item list*/
        public static getEnableStoreItemList() {
            let storeItems: dasa.StoreItem[] = dasa.Utility.storeItems;
            let disabledItemIds = [];
            if (dasa.Utility.storeSettings != undefined && dasa.Utility.storeSettings.disabledItemIds != undefined)
                disabledItemIds = dasa.Utility.storeSettings.disabledItemIds;
            let b: any = [];
            if (disabledItemIds != undefined && disabledItemIds.length > 0) {
                if (storeItems != undefined && storeItems.length > 0) {
                    for (let i: any = 0; i < storeItems.length; i++) {
                        let isDisabled: boolean = false;
                        for (let k: any = 0; k < disabledItemIds.length; k++) {
                            if (disabledItemIds[k] == storeItems[i].id) {
                                isDisabled = true;
                                break;
                            }
                        }
                        if (isDisabled == false && this.isDisabledBrand(storeItems[i].brandId) == false
                            && this.isDisabledCategory(storeItems[i].categoryId) == false) {
                            b[b.length] = storeItems[i];
                        }

                    }
                }
            } else {
                b = storeItems;
            }
            // console.log('getEnableBrandsList', storeItems)
            return this.getCopyOfAnObject(b);
        }

        public static getItemById(itemId: number): dasa.StoreItem {
            let item: dasa.StoreItem[] = dasa.Utility.storeItems;
            let b: any = {};
            if (item != undefined && item.length > 0) {
                for (let i: any = 0; i < item.length; i++) {
                    if (itemId == item[i].id) {
                        b = item[i];
                        break;
                    }
                }
            }
            return this.getCopyOfAnObject(b);
        }

        public static isDisabledCategory(categoryId: number): boolean {
            let isDisabled: boolean = false;
            let categories: any = dasa.Utility.categories;
            let disabledCategoryIds = dasa.Utility.storeSettings.disabledCategoryIds;
            if (disabledCategoryIds != undefined && disabledCategoryIds.length > 0) {
                for (let i = 0; i < disabledCategoryIds.length; i++) {
                    if (disabledCategoryIds[i] * 1 == categoryId * 1) {
                        isDisabled = true;
                        break;
                    }
                }
            }

            return isDisabled;
        }

        public static isDisabledBrand(brandId: number): boolean {
            let isDisabled: boolean = false;
            let brands: any = dasa.Utility.brands;
            let disabledBrandIds = dasa.Utility.storeSettings.disabledBrandIds;
            if (disabledBrandIds != undefined && disabledBrandIds.length > 0) {
                for (let i = 0; i < disabledBrandIds.length; i++) {
                    if (disabledBrandIds[i] * 1 == brandId * 1) {
                        isDisabled = true;
                        break;
                    }
                }
            }

            return isDisabled;
        }


        public static getStatusArray(): any {
            let status_data = [
                {
                    id: -1,
                    status: 'Select Status'
                },
                {
                    id: dasa.Constant.STATUS_ENABLED,
                    status: 'ENABLED'
                }, {
                    id: dasa.Constant.STATUS_DISABLED,
                    status: 'DISABLED'
                }
            ];
            return status_data;
        }

        /*
        Get user type buisness or buisness cum store admin
        */

        public static getUserTypeArray(): any {
            let usertype_data = [
                {
                    id: -1,
                    name: 'Select User Type'
                },
                {
                    id: dasa.Constant.USER_TYPE_BUSINESS_ADMIN,
                    name: 'Business Admin'
                }, {
                    id: dasa.Constant.USER_TYPE_BUSINESS_ADMIN_cum_STORE_ADMIN,
                    name: 'Business Cum Super Admin'
                }
            ];
            return usertype_data;
        }

        /*
        Get timingjson data
        */
        public static getTimingJson(): any {
            let timing_data = [
                { id: 1, day: "Monday", openingTime: "09:00", closingTime: "20:00" },
                { id: 2, day: "Tuesday", openingTime: "09:00", closingTime: "20:00" },
                { id: 3, day: "Wednesday", openingTime: "09:00", closingTime: "20:00" },
                { id: 4, day: "Thursday", openingTime: "09:00", closingTime: "20:00" },
                { id: 5, day: "Friday", openingTime: "09:00", closingTime: "20:00" },
                { id: 6, day: "Saturday", openingTime: "09:00", closingTime: "20:00" },
                { id: 7, day: "Sunday", openingTime: "09:00", closingTime: "20:00" },


            ];
            return timing_data;
        }

        public static getTimeObjectCurrentDay(storeTime: any[]): any {
            let day_value = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            let curr_date = new Date();
            let get_data = storeTime.filter(itm => itm.day === day_value[curr_date.getUTCDay()]);
            return get_data;
        }

        // public static getdRangeJson():any{
        //     let dRangeJson_data =[
        //         {startRange: "1",  endRange: "2", deliveryTime: "3"}
        //     ];
        //     return dRangeJson_data;

        // }

        /*
       Get dRangeJson data
       */
        public static getdRangeJson(): any {
            let timing_data = [
                { startRange: "", endRange: "", deliveryTime: "" },


            ];
            return timing_data;
        }

        /* Method to get is featured data */
        public static getIsFeaturedArray(): any {
            let isFeatured_data = [
                {
                    id: -1,
                    status: 'Select Featured Store'
                },
                {
                    id: dasa.Constant.FEATURED_STORE_YES,
                    status: 'Yes'
                },
                {
                    id: dasa.Constant.FEATURED_STORE_NO,
                    status: 'No'
                }
            ];
            return isFeatured_data;
        }


        /** 
         * Method to get delivery type
        **/

        public static getDeliveryTypeArray(): any {
            let deliverytype_data = [
                {
                    id: -1,
                    status: 'Select Delivery Type'
                },
                {
                    id: dasa.Constant.DELIVERY_TYPE_PICK_UP_ONLY,
                    status: 'Pick Up Only'
                },
                {
                    id: dasa.Constant.DELIVERY_TYPE_DELIVERY_ONLY,
                    status: 'Delivery Only'
                },
                {
                    id: dasa.Constant.DELIVERY_TYPE_PICKUP_DELIVERY_BOTH,
                    status: 'Pickup and Delivery'
                }

            ];
            return deliverytype_data;
        }

        /* Method to get is featured data */
        public static getDiscountTypeArray(): any {
            let discountType_data = [
                {
                    id: -1,
                    name: 'Select Discount Type'
                },
                {
                    id: dasa.Constant.DISCOUNT_TYPE_FLAT,
                    name: 'Flat'
                },
                {
                    id: dasa.Constant.DISCOUNT_TYPE_PERCENT,
                    name: 'Percent'
                }
            ];
            return discountType_data;
        }

        /*
        Get Discount type flat & percent
        */

        public static getDiscountType(id: number) {
            let value;
            if (id === dasa.Constant.DISCOUNT_TYPE_PERCENT) {
                value = "Percent";
            }
            else if (id === dasa.Constant.DISCOUNT_TYPE_FLAT) {
                value = "Flat";
            }
            return value;
        }

        /* Get CMS type  and passed type in function
        */

        public static getCMSType(id: number) {
            let value;
            if (id === 1) {
                value = "Driver";
            }
            else if (id === 2) {
                value = "Store";
            }
            else if (id === 3) {
                value = "Customer";
            }

            return value;
        }

        /*get unit type*/

        public static getUnitArray(): any {
            let unit_data = [
                {
                    id: -1,
                    name: 'Select Unit'
                },
                {
                    id: 1,
                    name: 'Kg'
                },
                {
                    id: 2,
                    name: 'Gm'
                },
                {
                    id: 3,
                    name: 'Ltrs'
                }
            ];
            return unit_data;
        }

        /**
         * Method used to get user by user id
         * @param userId 
         * @returns 
         */
        public static getUnitNameByID(unitId: number): string {
            let arry = this.getUnitArray();
            let unitName = "";
            for (let i: any = 0; i < arry.length; i++) {
                if (unitId == arry[i].id) {
                    unitName = arry[i].name;
                    break;
                }
            }
            return unitName;
        }
        /**
             * Method used to store latest utility object in session
             */
        public static putInSession(): void {
            // console.log('Inside put in storage');
            if (window && window.sessionStorage) {
                let sObject: SessionObject = new SessionObject();

                sObject.businesses = dasa.Utility.businesses;
                sObject.countries = dasa.Utility.countries;
                sObject.loggedInUser = dasa.Utility.loggedInUser;
                // console.log('Putting in session', sObject.loggedInUser)
                sObject.supportDetails = dasa.Utility.supportDetails;
                sObject.users = dasa.Utility.users;
                sObject.stores = dasa.Utility.stores;
                sObject.complains = dasa.Utility.complains;
                sObject.selectedBusinessId = dasa.Utility.selectedBusinessId;
                sObject.selectedStoreId = dasa.Utility.selectedStoreId;

                sObject.brands = dasa.Utility.brands;
                sObject.categories = dasa.Utility.categories;
                sObject.storeItems = dasa.Utility.storeItems;
                sObject.storeSettings = dasa.Utility.storeSettings;
                sObject.businessItems = dasa.Utility.businessItems;
                sObject.selectedCountry = dasa.Utility.selectedCountry;

                sObject.complains = dasa.Utility.complains;
                sObject.storeOrders = dasa.Utility.storeOrders;
                sObject.myReportData = dasa.Utility.myReportData;

                sObject.selectedCustomerForReport = dasa.Utility.selectedCustomerForReport;
                sObject.selectedCountryForReport = dasa.Utility.selectedCountryForReport;
                sObject.selectedBusinessForReport = dasa.Utility.selectedBusinessForReport;
                sObject.selectedStoreForReport = dasa.Utility.selectedStoreForReport;             


                sObject.carts = dasa.Utility.carts;

                sObject.socketId = dasa.Utility.socketId;
                sObject.latitude = dasa.Utility.latitude;
                sObject.longitude = dasa.Utility.longitude;
                sessionStorage.setItem("dasaDb", JSON.stringify(sObject));
                // console.log('Items stored in storage==>', dasa.Utility.myReportData);
            }
        }
        /**
         * Methos used to load from session
         */
        public static loadFromSession(): void {
            // console.log('Inside getFromStorage');
            if (window && window.sessionStorage) {
                let k: any = sessionStorage.getItem("dasaDb");
                if (k != null && k != undefined) {
                    // console.log('inside if condition');
                    let obj = JSON.parse(k);
                    let sObject = obj as SessionObject;
                    // console.log('sessionobject :: ' + sObject);
                    // console.log('sessionobject :: ' + sObject.selectedBusinessId);
                    dasa.Utility.businesses = sObject.businesses;
                    dasa.Utility.countries = sObject.countries;
                    dasa.Utility.loggedInUser = sObject.loggedInUser;
                    // console.log('loadFromSession in session', dasa.Utility.loggedInUser)

                    dasa.Utility.supportDetails = sObject.supportDetails;
                    dasa.Utility.users = sObject.users;
                    dasa.Utility.stores = sObject.stores;
                    dasa.Utility.selectedBusinessId = sObject.selectedBusinessId;
                    dasa.Utility.selectedStoreId = sObject.selectedStoreId;

                    dasa.Utility.brands = sObject.brands;
                    dasa.Utility.categories = sObject.categories;
                    dasa.Utility.storeItems = sObject.storeItems;
                    dasa.Utility.storeSettings = sObject.storeSettings;
                    dasa.Utility.businessItems = sObject.businessItems;
                    dasa.Utility.selectedCountry = sObject.selectedCountry;
                    dasa.Utility.storeOrders = sObject.storeOrders;
                    dasa.Utility.carts = sObject.carts;
                    // console.log('Item retreived from session ');
                    dasa.Utility.myReportData = sObject.myReportData;

                    dasa.Utility.selectedCustomerForReport = sObject.selectedCustomerForReport
                    dasa.Utility.selectedCountryForReport = sObject.selectedCountryForReport
                    dasa.Utility.selectedBusinessForReport = sObject.selectedBusinessForReport
                    dasa.Utility.selectedStoreForReport = sObject.selectedStoreForReport            
        

                    dasa.Utility.socketId = sObject.socketId;
                    dasa.Utility.latitude = sObject.latitude;
                    dasa.Utility.longitude = sObject.longitude;
                }
            }
        }

        /**
         * Method used to remove data from session
         * call this method at the time of logout
         */
        public static removeFromSession(): void {
            // console.log('Inside removeFromSession');
            if (window && window.sessionStorage) {
                sessionStorage.removeItem('dasaDb');
                dasa.Utility.businesses = [];
                dasa.Utility.countries = [];
                dasa.Utility.loggedInUser = {};
                dasa.Utility.supportDetails = [];
                dasa.Utility.selectedBusinessId = -1;
                dasa.Utility.selectedStoreId = -1;
                dasa.Utility.brands = [];
                dasa.Utility.categories = [];
                dasa.Utility.storeItems = [];
                dasa.Utility.storeSettings = {};
                dasa.Utility.businessItems = [];
                dasa.Utility.storeOrders = [];

            }
        }

        public static getPromoCodeById(id: any) {
            console.log(dasa.Utility.promoCodesList)
            const promoCode =  dasa.Utility.promoCodesList.find((x: any) => x.id == id);
            return promoCode;
        }


    }
    /**
    * All fields present in this class will be stored in session
    */
    export class SessionObject {
        public loggedInUser: any = {};
        public countries: any = [];
        public businesses: any = [];
        public supportDetails: any = [];
        public users: any = [];
        public stores: any = [];
        public selectedBusinessId: number = -1;
        public selectedStoreId: number = -1;
        public businessItems: any = [];
        public selectedCountry: dasa.Country = {} as dasa.Country;
        public complains: dasa.FeedbackComplain[] = [] as dasa.FeedbackComplain[];

        public storeSettings: any = {};
        public brands: any = [];
        public categories: any = [];
        public storeItems: any = [];
        public storeOrders: dasa.Order[] = [] as dasa.Order[];
        public carts: dasa.Cart[] = [] as dasa.Cart[]
        public myReportData: dasa.myReportData = {} as dasa.myReportData;
        public socketId: string = "";
        public latitude: number = -1;
        public longitude: number = -1;
        public selectedCustomerForReport: number = -1;
        public selectedCountryForReport: number = -1;
        public selectedBusinessForReport: number = -1;
        public selectedStoreForReport: number = -1;
        /**
         * Default constructor
         */
        constructor() {

        }
    }



}